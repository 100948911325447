import axios from "axios";
import { Button, Select, StateSelect, TextField } from "components";
import Grid from "elements/Grid";
import Search from "elements/Input/Search";
import {
	Dispatch,
	FormEventHandler,
	SetStateAction,
	useEffect,
	useState,
	useMemo,
} from "react";
import client from "utils/client";
import formatDollar from "utils/formatDollar";
import { PricingData, SavingsCopyType } from "utils/types";
import PriceTag from "public/pricing/price-tag.svg";
import { sendErrorToWebhook } from "utils/sendWebhook";
import { useOfferContext } from "context/OfferContext";
import Router, { useRouter } from "next/router";
import constants from "utils/constants";
import { useResponsiveContext } from "context/ResponsiveContext";
import { useAppContext } from "context/AppContext";
import { off } from "process";
import ButtonV2 from "elements/ButtonV2";
import ArrowRight from "public/pricing/arrow-right.svg";
import AzurePlusIcon from "public/plus-circle-azure.svg";
import {
	stateSpecificAppealWord,
	stateSpecificExemptionWord,
} from "utils/account";

const APPEAL_AVG_SAVINGS = 1148;
const EXEMPTION_REFUND_SAVING = 2156;
const EXEMPTION_AVG_SAVINGS = 762;
const TAX_SAVINGS_AMT = 1286.23;
const INSURANCE_SAVINGS = 290;
const BILL_REDUCTION_AVG_SAVINGS = 239;
const DEFAULT_CONTINGENCY = 25;
const PHONE_AVG_SAVINGS = 313;

const RenderServiceContent = ({
	name,
	avgSavings,
	content,
	subheading,
}: {
	name: string;
	avgSavings: string;
	content: string;
	subheading?: React.ReactNode;
}) => {
	const [showContent, setShowContent] = useState(false);
	return (
		<div
			className="pricing-container-service-box"
			onClick={() => setShowContent(!showContent)}>
			<div className="pricing-container-service-content">
				<p className="extra-large bold">{name}</p>
				<div className="pricing-container-service-content-right">
					<div className="avg-savings-pill">
						<p className="sm bold">Avg. Savings of {avgSavings}</p>
					</div>
					<AzurePlusIcon class={showContent ? "rotate-icon-45-deg" : ""} />
				</div>
			</div>
			{subheading && <div className="subheading">{subheading}</div>}
			<div
				className={
					"pricing-container-service-more-info mt-2" +
					(showContent ? "" : " pricing-container-service-more-info-hide")
				}>
				<div className="avg-savings-pill">
					<p className="sm bold">Avg. Savings of {avgSavings}</p>
				</div>
				<p className="sm denim-medium">{content}</p>
			</div>
		</div>
	);
};

const RenderPricingBodyContent = ({
	pricingData,
}: {
	pricingData: PricingData | undefined;
}) => {
	if (!pricingData) return null;
	const pricingState = pricingData?.state;
	const exemptionsPricing = (() => {
		if (pricingState === "TX") return formatDollar(1973);
		if (pricingState === "GA") return formatDollar(500);
		if (pricingState === "CA") return formatDollar(86);
		if (pricingState === "FL") return formatDollar(743);
		if (pricingState === "MI") return formatDollar(2071);
		if (pricingState === "PA") return formatDollar(529);

		return formatDollar(EXEMPTION_AVG_SAVINGS);
	})();

	const exemptionsRefund = (() => {
		if (pricingState === "TX") return formatDollar(2156);
		if (pricingState === "NY") return formatDollar(1100);
		if (pricingState === "MI") return formatDollar(2088);

		return formatDollar(EXEMPTION_REFUND_SAVING);
	})();

	const pricing_content = {
		appeal: {
			title: `Property Tax ${stateSpecificAppealWord(pricingState, {
				capitalize: true,
			})}`,
			avgSavings: formatDollar(APPEAL_AVG_SAVINGS),
			content: `A property tax ${stateSpecificAppealWord(
				pricingState
			)} can reduce the amount of property taxes you owe. We charge ${
				pricingData?.pricing.appeal_contingency ?? DEFAULT_CONTINGENCY
			}% of your tax savings, so if you don't save anything, you won't be charged.`,
		},
		exemptionRefunds: {
			title: `${stateSpecificExemptionWord(pricingState, {
				capitalize: true,
			})} Refunds`,
			avgSavings: exemptionsRefund,
			content: `If you qualified for ${stateSpecificExemptionWord(
				pricingState,
				{ plural: true }
			)} in past years, we can help you assemble the evidence necessary to get you a refund for overpaid taxes. We only charge ${
				pricingData?.pricing.exemption_primary_contingency
			}% of the refund amount. If you don't save, you don't pay.`,
		},
		exemptionFiling: {
			title: `${stateSpecificExemptionWord(pricingState, {
				capitalize: true,
			})} Filing`,
			avgSavings: exemptionsPricing,
			content: `Find and apply for tax ${stateSpecificExemptionWord(
				pricingState,
				{ plural: true }
			)} to lower the taxable value of your home. ${stateSpecificExemptionWord(
				pricingState,
				{ capitalize: true, plural: true }
			)} can reduce your property taxes every year and potential limit annual increases. We file and ensure your primary residence ${stateSpecificExemptionWord(
				pricingState
			)} is filed for free!`,
		},
		exemptionFirstYear: {
			title: `${stateSpecificExemptionWord(pricingState, {
				capitalize: true,
			})} 1st Year Savings`,
			avgSavings: exemptionsPricing,
			content: `Find and apply for tax ${stateSpecificExemptionWord(
				pricingState
			)} to lower the taxable value of your home. ${stateSpecificExemptionWord(
				pricingState,
				{ capitalize: true, plural: true }
			)} can reduce your property taxes every year and potential limit annual increases. We only charge ${
				pricingData?.pricing.appeal_contingency ?? DEFAULT_CONTINGENCY
			}% of the expected first year savings.`,
		},
		exemptionFutureYears: {
			title: `All Future ${stateSpecificExemptionWord(pricingState, {
				capitalize: true,
			})} Years`,
			avgSavings: exemptionsPricing + "/yr",
			content: `${stateSpecificExemptionWord(pricingState, {
				capitalize: true,
				plural: true,
			})} will continue to help you save year over year! We will continue to monitor your property taxes annually and keep your ${stateSpecificExemptionWord(
				pricingState,
				{ plural: true }
			)} active.`,
		},
		internet: {
			title: "Internet Bill Reduction",
			avgSavings: formatDollar(BILL_REDUCTION_AVG_SAVINGS) + "/yr",
			content: `Share your internet bill, and our experts will work with your provider to uncover savings. We regularly monitor and compare your bills to ensure you're getting the best deal. There's no upfront cost, and you only pay ${
				pricingData?.pricing.appeal_contingency ?? DEFAULT_CONTINGENCY
			}% of the savings we find. If you don't save, our service is free.`,
		},
		phone: {
			title: "Phone Bill Reduction",
			avgSavings: formatDollar(PHONE_AVG_SAVINGS) + "/yr",
			content: `Share your phone bill, and our experts will work with your provider to find potential savings. We continuously monitor and compare your bills to ensure you're not overpaying. There's no upfront cost, and you only pay ${
				pricingData?.pricing.appeal_contingency ?? DEFAULT_CONTINGENCY
			}% of the savings we secure. If you don't save, our service is free.`,
		},
		insurance: {
			title: "Insurance Monitoring",
			avgSavings: formatDollar(INSURANCE_SAVINGS) + "/yr",
			content: `Automatically ensure you have the best insurance rate and coverage. Answer a few questions, and an Ownwell insurance concierge will shop around to find you savings. We will regularly compare your rates to ensure you're not overpaying.`,
		},
	};

	const shouldRenderAppealPricingRow =
		!!pricingData.pricing.accepting_appeals &&
		(!!pricingData.pricing.appeal_contingency ||
			!!pricingData.pricing.appeal_flat);

	const shouldRenderExemptionsPricingRow =
		!!pricingData.pricing.accepting_exemptions &&
		(!!pricingData.pricing.exemption_primary_contingency ||
			!!pricingData.pricing.exemption_primary_flat);

	const isExemptionsRefund = constants.STATES_SERVED_WITH_RETRO.includes(
		pricingState as "TX" | "IL" | "NY" | "MI"
	);

	const GeorgiaFreezeCard = () => {
		return (
			<div id="pricing-georgia-freeze-card">
				<div id="pricing-georgia-freeze-pill">
					<p className="sm bold">Additional Benefit</p>
				</div>
				<p className="sm text-center">
					When and where applicable, we automatically pursue a tax assessment
					freeze. This prevents your assessment from going up for 3 years for an
					additional $100 only. The charge is applied to your invoice only if a
					freeze is earned.
				</p>
			</div>
		);
	};

	return (
		<>
			<div className="pricing-calculator-results-container">
				<div className="pricing-container-header">
					<p className="lg bold">pay only</p>
					<h2 className="pricing-contingency-title">
						{pricingData.pricing.appeal_contingency ?? DEFAULT_CONTINGENCY}%
					</h2>
					<p className="lg bold">of what you save</p>
					<p className="sm pricing-left-side-price-info denim-medium">
						You are charged a percentage on your bill savings. If you save
						$1,000 you will receive an invoice for{" "}
						{pricingData?.pricing.appeal_contingency ?? DEFAULT_CONTINGENCY}% of
						that which is&nbsp;$
						{(1000 *
							(pricingData.pricing.appeal_contingency ?? DEFAULT_CONTINGENCY)) /
							100}
						.
					</p>
				</div>
				<div className="pricing-container-services-content">
					{shouldRenderAppealPricingRow && (
						<RenderServiceContent
							name={pricing_content.appeal.title}
							avgSavings={pricing_content.appeal.avgSavings}
							content={pricing_content.appeal.content}
							subheading={
								<p className="pricing-content-subheading">
									For commercial{" "}
									{stateSpecificAppealWord(pricingState, { capitalize: false })}{" "}
									pricing: <span> </span>
									<a
										href="https://www.ownwell.com/commercial#commercial-contact-form"
										target="_blank"
										rel="noopener noreferrer">
										Contact Sales <ArrowRight />
									</a>
								</p>
							}
						/>
					)}
					{shouldRenderExemptionsPricingRow &&
						(isExemptionsRefund ? (
							<RenderServiceContent
								name={pricing_content.exemptionRefunds.title}
								avgSavings={pricing_content.exemptionRefunds.avgSavings}
								content={pricing_content.exemptionRefunds.content}
							/>
						) : (
							<RenderServiceContent
								name={pricing_content.exemptionFirstYear.title}
								avgSavings={pricing_content.exemptionFirstYear.avgSavings}
								content={pricing_content.exemptionFirstYear.content}
							/>
						))}
					<RenderServiceContent
						name={pricing_content.internet.title}
						avgSavings={pricing_content.internet.avgSavings}
						content={pricing_content.internet.content}
					/>
					<RenderServiceContent
						name={pricing_content.phone.title}
						avgSavings={pricing_content.phone.avgSavings}
						content={pricing_content.phone.content}
					/>
				</div>
			</div>
			<div className="pricing-calculator-results-container pricing-calculator-results-container-green mt-2">
				<div className="pricing-container-header">
					<p className="lg bold">Included for</p>
					<h2 className="pricing-contingency-title">Free!</h2>
				</div>
				<div className="pricing-container-services-content">
					{shouldRenderExemptionsPricingRow &&
						(isExemptionsRefund ? (
							<RenderServiceContent
								name={pricing_content.exemptionFiling.title}
								avgSavings={pricing_content.exemptionFiling.avgSavings}
								content={pricing_content.exemptionFiling.content}
							/>
						) : (
							<RenderServiceContent
								name={pricing_content.exemptionFutureYears.title}
								avgSavings={pricing_content.exemptionFutureYears.avgSavings}
								content={pricing_content.exemptionFutureYears.content}
							/>
						))}
					<RenderServiceContent
						name={pricing_content.insurance.title}
						avgSavings={pricing_content.insurance.avgSavings}
						content={pricing_content.insurance.content}
					/>
				</div>
			</div>
			{pricingState === "GA" && <GeorgiaFreezeCard />}
			<div id="pricing-calculator-cta">
				<ButtonV2
					size="large"
					className="mx-auto mt-2"
					toAppealFlow={{
						referrer: "Pricing Calculator CTA",
					}}>
					Sign Up
				</ButtonV2>
			</div>
		</>
	);
};

export const PricingCalculator = ({
	showBillBreakdown = false,
}: {
	showBillBreakdown?: boolean;
}) => {
	const [zipCodeInput, setZipCodeInput] = useState("");
	const [errorCode, setErrorCode] = useState<number | null>(null);
	const [loading, setLoading] = useState(false);
	const [pricingData, setPricingData] = useState<PricingData | null>(null);
	const [usedZipCodeInput, setUsedZipCodeInput] = useState("");

	const [waitlistFname, setWaitlistFname] = useState("");
	const [waitlistLname, setWaitlistLname] = useState("");
	const [waitlistEmail, setWaitlistEmail] = useState("");
	const [waitlistAddress, setWaitlistAddress] = useState("");
	const [waitlistAddress2, setWaitlistAddress2] = useState("");
	const [waitlistCity, setWaitlistCity] = useState("");
	const [waitlistState, setWaitlistState] = useState("");
	const [submittedWaitlist, setSubmittedWaitlist] = useState<boolean>(false);

	const router = useRouter();

	const { landing, referrer } = useAppContext();
	const { offer, partnerRef } = useOfferContext();
	const { isMobile } = useResponsiveContext();

	const onSubmit: FormEventHandler = async e => {
		try {
			setLoading(true);
			setErrorCode(null);
			e.preventDefault();
			const data = await client.getPricingByZip({ zipCode: zipCodeInput });

			setUsedZipCodeInput(zipCodeInput);
			setPricingData(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				setErrorCode(err.response?.status ?? 500);
			}
			setErrorCode(500);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const zipCode = router.query?.zipCode as string;
		const handleZipCodeFromPricingComponent = async () => {
			if (router.query.zipCode) {
				try {
					setZipCodeInput(zipCode);
					const data = await client.getPricingByZip({ zipCode });

					setUsedZipCodeInput(zipCode);
					setPricingData(data);
				} catch (err) {
					if (axios.isAxiosError(err)) {
						setErrorCode(err.response?.status ?? 500);
					}
					setErrorCode(500);
				} finally {
					setLoading(false);
				}
			}
		};
		handleZipCodeFromPricingComponent();
	}, [router.query?.zipCode]);

	const handleWaitlistSubmit: FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();
		try {
			await client.logContactStep({
				address: waitlistAddress,
				apn: "",
				line2: "",
				city: waitlistCity,
				state: waitlistState,
				zip: zipCodeInput,
				county: pricingData?.county ?? "Unknown",
				session: "",
				savings: null,
				company: "",
				phone: "",
				discount: undefined,
				addressRaw: "",
				email: waitlistEmail,
				fname: waitlistFname,
				lname: waitlistLname,
				referralCode: undefined,
				isMailer: false,
				savingsType: undefined,
				savingsCopyType: "Pricing Page" as SavingsCopyType,
				assessment: undefined,
				past_deadline: false,
				partner_ref: partnerRef,
				landing,
				referrer,
				campaign: offer,
				showSavingsBox: null,
			});

			setSubmittedWaitlist(true);
		} catch (err: any) {
			sendErrorToWebhook(`Error during logContactStep:\n${err.toString()}`);
		}
	};

	const renderContactFormBody = () => {
		if (submittedWaitlist) {
			return (
				<h4 className="text-center mt-6 mb-6">
					Thank you! We look forward to serving you soon.
				</h4>
			);
		}

		return (
			<>
				<div className="pricing-waitlist-input-row">
					<TextField
						required
						placeholder="First Name"
						label="First Name"
						value={waitlistFname}
						onChange={e => setWaitlistFname(e.target.value)}
					/>
					<TextField
						required
						placeholder="Last Name"
						label="Last Name"
						value={waitlistLname}
						onChange={e => setWaitlistLname(e.target.value)}
					/>
				</div>
				<TextField
					containerClassName="pricing-waitlist-input"
					required
					placeholder="Email"
					label="Email"
					value={waitlistEmail}
					onChange={e => setWaitlistEmail(e.target.value)}
				/>
				<TextField
					containerClassName="pricing-waitlist-input"
					required
					label="Address"
					placeholder="Street Address"
					value={waitlistAddress}
					onChange={e => setWaitlistAddress(e.target.value)}
				/>
				<TextField
					className="mt-1"
					placeholder="Apt/Suite/Unit"
					value={waitlistAddress2}
					onChange={e => setWaitlistAddress2(e.target.value)}
				/>
				<TextField
					containerClassName="pricing-waitlist-input"
					required
					label="City"
					placeholder="City"
					value={waitlistCity}
					onChange={e => setWaitlistCity(e.target.value)}
				/>
				<div className="pricing-waitlist-input-row-mt">
					<StateSelect
						label="State"
						placeholder="State"
						value={waitlistState}
						onChange={e => setWaitlistState(e.target.value)}
					/>
					<TextField
						required
						label="Zip"
						placeholder="Zip Code"
						readOnly
						value={usedZipCodeInput}
					/>
				</div>
				<ButtonV2
					size="large"
					type="submit"
					className="mx-auto mt-2"
					disabled={
						!waitlistFname ||
						!waitlistLname ||
						!waitlistEmail ||
						!waitlistAddress ||
						!waitlistCity ||
						!waitlistState
					}>
					Submit
				</ButtonV2>
			</>
		);
	};

	return (
		<div id="pricing-calculator">
			<Grid>
				<div id="pricing-calculator-input-section">
					<h3>View pricing in your area</h3>
					<form id="pricing-calculator-input-container" onSubmit={onSubmit}>
						<Search
							placeholder="Enter Zip Code"
							value={zipCodeInput}
							onChange={e => setZipCodeInput(e.target.value)}
							numeric
							maxLength={5}
						/>
						<ButtonV2
							size="large"
							type="submit"
							disabled={zipCodeInput.length !== 5}
							className={
								pricingData ? "pricing-calculator-button-outlined" : ""
							}>
							View Pricing
						</ButtonV2>
					</form>
					{errorCode && (
						<p className="error-text text-center">
							Whoops, something went wrong. Please try again.
						</p>
					)}
				</div>
				{(loading || pricingData) && (
					<div id="pricing-calculator-results-heading">
						<p className="lg">
							Showing you services and pricing for '
							<b className="no-translate">{usedZipCodeInput}</b>'
						</p>
					</div>
				)}
				{loading && (
					<div id="pricing-calculator-loading-container">
						<div className="pricing-calculator-loading-1" />
						<div className="pricing-calculator-loading-2-container">
							<div className="pricing-calculator-loading-2" />
							<div className="pricing-calculator-loading-2" />
						</div>
					</div>
				)}
				{!loading && pricingData && (
					<RenderPricingBodyContent pricingData={pricingData} />
				)}
				{pricingData && showBillBreakdown && (
					<div id="pricing-calculate-bill-breakdown">
						<div className="pricing-calculate-bill-breakdown-title-section">
							<h3 className="h3-update">How we calculate your bill</h3>
							<div className="pricing-calculate-bill-breakdown-badge">
								<p className="sm bold">Sample Calculation</p>
							</div>
						</div>
						<div id="pricing-calculate-your-bill">
							<Grid>
								<div className="pricing-calculate-your-bill-steps">
									<div>
										<p className="lg bold">Step 1</p>
										<p>
											Initial Assessment - Final Assessment
											=&nbsp;Assessment&nbsp;Reduction
										</p>
									</div>
								</div>
								<div className="pricing-calculate-your-bill-tables">
									<table>
										<tbody>
											<tr>
												<td>Initial Assessment*</td>
												<td>$774,500</td>
											</tr>
											<tr>
												<td className="pricing-calculate-your-bill-td-with-math-symbols">
													Final Assessment{" "}
													<span className="pricing-calculate-your-bill-math-symbols">
														-
													</span>
												</td>
												<td>$721,350</td>
											</tr>
											<tr className="pricing-calculate-your-bill-total-row">
												<td>Assessment Reduction</td>
												<td>$53,150</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="pricing-calculate-your-bill-steps">
									<div>
										<p className="lg bold">Step 2</p>
										<p>
											Assessment Reduction x County Tax Rate
											=&nbsp;Tax&nbsp;Savings
										</p>
									</div>
								</div>
								<div
									className="pricing-calculate-your-bill-tables"
									style={{ position: "relative" }}>
									<table>
										<tbody>
											<tr>
												<td>Assessment Reduction</td>
												<td>$53,150</td>
											</tr>
											<tr>
												<td className="pricing-calculate-your-bill-td-with-math-symbols">
													County Tax Rate*
													<span className="pricing-calculate-your-bill-math-symbols">
														x
													</span>
												</td>
												<td>2.42%</td>
											</tr>
											<tr className="pricing-calculate-your-bill-total-row">
												<td>Tax Savings</td>
												<td>${TAX_SAVINGS_AMT}</td>
											</tr>
										</tbody>
									</table>
									<div className="pricing-calculate-your-bill-sample-overlay">
										SAMPLE
									</div>
								</div>
								<div className="pricing-calculate-your-bill-steps">
									<div>
										<p className="lg bold">Step 3</p>
										<p>
											(Tax Savings x Contingency Fee) - Referral Credit
											=&nbsp;Amount&nbsp;Due
										</p>
									</div>
								</div>
								<div className="pricing-calculate-your-bill-tables pricing-calculate-your-bill-tables-final">
									<table>
										<tbody>
											<tr>
												<td>Tax Savings</td>
												<td>
													{formatDollar(TAX_SAVINGS_AMT, {
														withCents: "when-necessary",
													})}
												</td>
											</tr>
											<tr>
												<td className="pricing-calculate-your-bill-td-with-math-symbols">
													Contingency Fee
													<span className="pricing-calculate-your-bill-math-symbols">
														x
													</span>
												</td>
												<td>
													{pricingData?.pricing.appeal_contingency
														? pricingData.pricing.appeal_contingency
														: "25"}
													%
												</td>
											</tr>
											<tr>
												<td className="pricing-calculate-your-bill-td-with-math-symbols">
													Referral Credit
													<span className="pricing-calculate-your-bill-math-symbols">
														-
													</span>
												</td>
												<td>${constants.REFERRAL_CREDIT}</td>
											</tr>
											<tr>
												<td>Amount Due</td>
												<td>
													{formatDollar(
														TAX_SAVINGS_AMT *
															(pricingData?.pricing?.appeal_contingency
																? pricingData.pricing.appeal_contingency / 100
																: 0.25) -
															constants.REFERRAL_CREDIT,
														{ withCents: "when-necessary" }
													) ?? 0}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="pricing-calculate-your-bill-footnote">
									<label>
										*We will adjust your initial assessment and tax rate to
										reflect all discounts associated with your active
										{stateSpecificExemptionWord(pricingData?.state)}(s). You
										will never be charged for a reduction in your assessed value
										if your final tax bill is not reduced.
									</label>
								</div>
							</Grid>
						</div>
					</div>
				)}
				{!pricingData && (
					<div id="pricing-calculator-results-footer">
						<p className="lg bold azure">Only pay if you save</p>
						<p>No up-front costs | No hidden fees</p>
					</div>
				)}
			</Grid>
		</div>
	);
};
